.procedures-page {
    position: relative;
    min-height: 100vh;
  }
  
  .procedure-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('./content/procedures.png') center center no-repeat;
    background-size: cover;
    transition: opacity 2s ease-out, visibility 2s ease-out;
    z-index: -1;
    margin-top: -70px;
    
  }
  
  .procedure-background-image.visible {
    opacity: 1;
    visibility: visible;
  }
  
  .procedure-background-image.fade-out {
    opacity: 0;
    visibility: hidden;
  }
  
  .pro-content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 2s ease-in;
    /* height: calc(110vh - 90px - 200px); Adjust based on header and footer heights */
    margin-top: 60px; /* Add space to push the content down if needed */
  
  }
  
  .pro-content-wrapper.visible {
    opacity: 1;
  }
  
  /* .title-image-container {
    position: absolute;
    top: -250px;
    left: 30px;
  } */
  
  .pro-title-image {
    width: 700px;
    position: absolute;
    top: -30px;
    left: 380px;
    z-index: -1;
  }
  