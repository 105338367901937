.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-body {
    display: flex;
    align-items: center;
  }
  
  .modal-text {
    width: 60%;
    padding-right: 20px;
  }
  
  .modal-text h2 {
    margin-top: 0;
    font-family: 'ALTA', sans-serif;
    font-size: 34px;
  }
  
  .modal-text p {
    margin: 20px 0;
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
  }
  
  .modal-image-container {
    width: 40%;
    text-align: center;
  }
  
  .modal-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .book-button {
    display: block;
    margin-top: 20px;
    margin-left: 160px;
    padding: 10px 20px;
    background-color: #243F5B;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'ALTA', sans-serif;
  }
  
  .book-button:hover {
    background-color: #e5edf3;
    color: #243F5B;
  }
  
  