.surgeries-list {
    display: flex;
    overflow-x: auto;
    padding: 70px;
  }
  
  .surgery-card {
    flex: 0 0 auto;
    width: 200px;
    height: 410px;
    margin: 0 15px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .surgery-card:hover {
    transform: scale(1.05);
  }
  
  .surgery-image {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .surgery-name {
    padding: 10px;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    color: #243F5B;
  }
  