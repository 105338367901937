@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato/Lato-Italic.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

@keyframes floatingGradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .SurgeonProfile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 150px auto; /* Center the profile on the page */
    z-index: 2; /* Ensure this content is above the background */
  }
  
  .SurgeonProfile-background {
    position: absolute;
    top: 50px;
    left: 20%; /* Position it at the center of the parent */
    transform: translateX(-50%); /* Offset it to the left by half its width */
    width: 80%; /* Set the desired width; adjust as needed */
    max-width: 1130px; /* Maximum width it can expand to */
    height: 50%; /* Full height of the parent */
    border-radius: 30px; /* Rounded corners if desired */
    box-shadow: 0 4px 18px #243F5B; 
    /* Optional shadow for some depth */
    z-index: -1; /* Behind the content */
    background: linear-gradient(230deg, #243F5B, #D6CBC2, #243F5B);
    background-size: 200% 200%; /* Adjust to fit the gradient size you need */
    animation: floatingGradient 10s ease-in-out infinite;
  }
  
  .SurgeonProfile-content {
    position: relative;
    display: flex;
    align-items: center; /* Center the items vertically */
    justify-content: center; /* Center the items horizontally */
    padding: 20px; /* Add some padding around the content */
    z-index: 3; /* Above the background */
  }
  
  /* ... other existing styles ... */
  
  
  .SurgeonProfile-image img {
    width: 400px; /* Adjust size as needed */
    height: 430px; /* Keep square aspect ratio */
    object-fit: cover; /* Ensures the image covers the area without distortion */
    margin-left: 50px;
    margin-top: 130px;
    filter: drop-shadow(10px 27px 20px #243F5B)
  }
  
  .SurgeonProfile-info {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the items vertically */
    text-align: center; /* Center the text */
    margin-left: 90px; /* Space between the image and the info */
    margin-bottom: 100px;
  }
  
  .Clinic-logo {
    width: 400px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: -20px; /* Space between logo and description */
    margin-top: 150px;
    margin-left: 20px;
    
  }
  
  .Surgeon-description {
  font-family: 'Lato', sans-serif; 
  color: #ffffff; /* Use a color that matches the theme */
  max-width: 600px; /* Maximum width of the text to maintain readability */
  font-size: 1.20em;
  margin-top: 60px;
  margin-left: 40px;
  margin-bottom: 30px;
}

.button-about-btn {
  position: absolute;
  top: 550px; /* Adjust this value as needed */
  right: 690px; /* Adjust this value as needed */
  width: 170px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  border: none; /* Remove the rectangular border */
  background-color: transparent; /* Transparent background */
  color: white; /* Adjust if you have a different preference */
  font-size: 16px; /* Adjust based on your design needs */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'ALTA', sans-serif;
  border-bottom: 2px solid white; /* Add a line under the button */
}

.button-about-btn:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Slight white tint on hover for feedback */
}

/* Another Styles */
@media (min-width: 1600px) {
  .SurgeonProfile {
    margin: 100px auto; /* Adjust margin for large desktops */
  }

  .SurgeonProfile-background {
    position: absolute;
    top: 0px;
    left: 8%; /* Position it at the center of the parent */
    transform: translateX(-50%); /* Offset it to the left by half its width */
    width: 85%; /* Set the desired width; adjust as needed */
    max-width: 1530px; /* Maximum width it can expand to */
    height: 60%; /* Full height of the parent */
    border-radius: 30px; /* Rounded corners if desired */
    box-shadow: 0 4px 18px #243F5B; 
    /* Optional shadow for some depth */
    z-index: -1; /* Behind the content */
    background: linear-gradient(230deg, #243F5B, #D6CBC2, #243F5B);
    background-size: 200% 200%; /* Adjust to fit the gradient size you need */
    animation: floatingGradient 10s ease-in-out infinite;
    margin-left: -20px;
  }

  .SurgeonProfile-content {
    padding: 30px; /* Adjust padding for large screens */
  }

  .SurgeonProfile-image img {
    width: 390px; /* Slightly smaller image */
    height: 410px; /* Keep proportions */
    margin-left: 100px;
    margin-top: 120px;
    margin-right: 60px;
  }

  .Clinic-logo {
    width: 350px;
    margin-top: 100px;
    margin-left: 0px;
  }

  .Surgeon-description {
    font-size: 1.1em;
    max-width: 550px;
  }

  .button-about-btn {
    font-size: 14px;
    top: 490px; /* Adjust top position */
    right: 770px;
  }
}


@media (max-width: 1600px) {
  .SurgeonProfile {
    margin: 100px auto; /* Adjust margin for large desktops */
  }

  .SurgeonProfile-background {
    position: absolute;
    top: 0px;
    left: 10%; /* Position it at the center of the parent */
    transform: translateX(-50%); /* Offset it to the left by half its width */
    width: 85%; /* Set the desired width; adjust as needed */
    max-width: 1130px; /* Maximum width it can expand to */
    height: 60%; /* Full height of the parent */
    border-radius: 30px; /* Rounded corners if desired */
    box-shadow: 0 4px 18px #243F5B; 
    /* Optional shadow for some depth */
    z-index: -1; /* Behind the content */
    background: linear-gradient(230deg, #243F5B, #D6CBC2, #243F5B);
    background-size: 200% 200%; /* Adjust to fit the gradient size you need */
    animation: floatingGradient 10s ease-in-out infinite;
    margin-left: -20px;
  }

  .SurgeonProfile-content {
    padding: 30px; /* Adjust padding for large screens */
  }

  .SurgeonProfile-image img {
    width: 390px; /* Slightly smaller image */
    height: 410px; /* Keep proportions */
    margin-left: 10px;
    margin-top: 120px;
    margin-right: 60px;
  }

  .Clinic-logo {
    width: 350px;
    margin-top: 100px;
  }

  .Surgeon-description {
    font-size: 1.1em;
    max-width: 550px;
  }

  .button-about-btn {
    font-size: 14px;
    top: 520px; /* Adjust top position */
    right: 670px;
  }
}

/* For tablets and smaller desktops (between 1024px and 1600px) */
@media (max-width: 1024px) {
  .SurgeonProfile {
    flex-direction: column; /* Stack elements vertically */
    margin: 70px auto; /* Centered profile */
  }

  .SurgeonProfile-background {
    position: absolute;
    top: 0px;
    left: 10%; /* Position it at the center of the parent */
    transform: translateX(-50%); /* Offset it to the left by half its width */
    width: 85%; /* Set the desired width; adjust as needed */
    max-width: 1130px; /* Maximum width it can expand to */
    height: 60%; /* Full height of the parent */
    border-radius: 30px; /* Rounded corners if desired */
    box-shadow: 0 4px 18px #243F5B; 
    /* Optional shadow for some depth */
    z-index: -1; /* Behind the content */
    background: linear-gradient(230deg, #243F5B, #D6CBC2, #243F5B);
    background-size: 200% 200%; /* Adjust to fit the gradient size you need */
    animation: floatingGradient 10s ease-in-out infinite;
    margin-left: -20px;
  }

  .SurgeonProfile-image img {
    width: 270px; /* Smaller image for smaller screens */
    height: 260px;
    margin-left: 0; /* Center image on smaller screens */
    margin-top: 190px;
    margin-right: 40px;
  }

  .Clinic-logo {
    width: 300px; /* Smaller logo */
    margin-top: 100px;
  }

  .Surgeon-description {
    font-size: 1em; /* Slightly smaller font */
    max-width: 100%; /* Allow more room for text */
    margin-left: -60px;
    margin-top: 100px;
  }

  .button-about-btn {
    font-size: 12px;
    top: 510px; /* Adjust top position */
    right: 450px;
  }
}

@media (max-width: 768px) {
  .App-slideshow {
    z-index: 1; /* Keep slideshow higher */
    position: relative;
  }

  .SurgeonProfile {
    position: relative;
    margin: 50px auto;
    z-index: -1;
  }

  .SurgeonProfile-background {
    margin-top: 30px;
    position: absolute;
    top: -120px;
    left: 8%; /* Position it at the center of the parent */
    transform: translateX(-50%); /* Offset it to the left by half its width */
    width: 80%; /* Set the desired width; adjust as needed */
    max-width: 100%; /* Maximum width it can expand to */
    height: 90%; /* Full height of the parent */
    border-radius: 30px; /* Rounded corners if desired */
    box-shadow: 0 4px 18px #243F5B; 
    /* Optional shadow for some depth */
    z-index: -100; /* Behind the content */
    background: linear-gradient(230deg, #243F5B, #D6CBC2, #243F5B);
    background-size: 200% 200%; /* Adjust to fit the gradient size you need */
    animation: floatingGradient 10s ease-in-out infinite;
    margin-left: 10px;
  }


  .SurgeonProfile-content {
      flex-direction: column; /* Stack elements vertically on small screens */
      max-width: 100%;
      
    }

  .SurgeonProfile-image img {
    position: relative;
    width: 100%; /* Adjust size for mobile */
    margin: 0 0;
    margin-top: 0px;   
    right: -3px;
    width: 250px;

  }

  .SurgeonProfile-info {
      align-items: center;
      text-align: center; /* Center text on small screens */
      margin: 0px 0;
      margin-left: 100px;
  }

  .Clinic-logo {
      content: url('./clinic-logo-mobile.png');
      width: 250px; /* Smaller logo for mobile */
      margin-top: 30px;
      margin-left: -100px;
  }

  .Surgeon-description {
      font-size: 0.9em;
      max-width: 100%;

      
  }

  .button-about-btn {
    position: absolute; /* Positioned relative to the parent */
    top: 103%; /* Center vertically */
    left: 52%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the button exactly */
    width: 140px; /* Smaller button size */
    height: 35px; /* Adjusted height */
    font-size: 14px; /* Adjusted font size */
  }
}

