html, body {
  height: 100%; /* Full height of the viewport */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  display: flex;
  flex-direction: column; /* Ensures content is laid out vertically */
}


.main-content {
  flex: 1 0 auto; /* Flex-grow to take available space, flex-shrink to avoid shrinking, and no base size */
  padding: 20px; /* Example padding, adjust based on your design */
}


.App-footer {
  background-color: #243F5B;
  background-size: cover; /* Cover the entire footer area */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the image */
  color: #F0ECEB; /* Text color for visibility */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 40px #243F5B);
  height: 200px; /* Set a fixed height */
  padding: 0px; /* Padding inside the footer */
  width: 100%; /* Ensure it spans the full width */
  flex-shrink: 0; /* Prevent the footer from shrinking */
}




/* Other styles remain the same */
.footer-address {
  color: #F0ECEB;
  width: 100%;
  text-align: center;
  margin: 0px 0px; /* Space between rows */
  margin-left: -310px;
  /* margin-top: 60px; */
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  position: relative;
  top: -50px;
}
.footer-contact {
  color: #F0ECEB;
  width: 100%;
  text-align: center;
  /* margin: -10px 0px; Space between rows */
  margin-left: -450px;
  /* margin-top: 0px; */
  z-index: 1;
  position: relative;
  top:-10px;
  
}
  
  .footer-logo {
    width: 500px;
    height: 90px;
    /* margin-bottom: 0px; Space between logo and address */
    align-content: center;
    /* margin-left: 0px;
    margin-top: 70px; */
    z-index: 1;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    top: 50px; /* Moves the element 20px down from where it would normally be */
  }
  
  /* .footer-address, */
  

  .footer-privacy {
    width: 50%;
    text-align: center;
    /* margin: 10px 0; Space between rows */
    position: relative;
    top: -60px;
    font-size: 0.7em;
  }

  .footer-bottom {
    width: 50%;
    text-align: center;
    margin: 0px 0; /* Space between rows */
    position: relative;
    top: -65px;
    font-size: 0.7em;
  }
  
  .social-icons {
    display: flex;
    justify-content: right;
    gap: 45px;
    margin: -60px 0; 
    /* Space between icons and contact info */
    margin-right: -280px;
    margin-top: 50px;
    z-index: 1;
    position: relative;
    top: -70px;
  }
  
  
  .social-icons svg {
    width: 44px;
    height: 44px;
    cursor: pointer;
  }
  
  .social-icons svg:hover {
    fill: #F0ECEB;
  }
  
  .social-icons2 {
    display: flex;
    justify-content: right;
    gap: 45px;
    margin: -60px 0; 
    /* Space between icons and contact info */
    right: -460px;
    margin-top: -100px;
    margin-bottom: -10px;
    z-index: 1;
    position: relative;
    
  }
  
  
  .social-icons2 svg {
    width: 44px;
    height: 44px;
    cursor: pointer;
  }
  
  .social-icons2 svg:hover {
    fill: #F0ECEB;
  }
  

  .footer-social-contact {
    color: #F0ECEB;
    text-decoration: none;
    /* margin-top: -120px; */
  }
  .footer-social-contact2 {
    color: #F0ECEB;
    text-decoration: none;
    /* margin-top: -120px; */
  }
  .footer-privacy {
    color: #F0ECEB;
    text-decoration: none;
    /* margin-top: 8px; */
  }
  .footer-bottom {
    color: #F0ECEB;
    text-decoration: none;
    /* margin-top: 10px; */
  }
  
  .footer-social-contact a:hover,
  .footer-privacy a:hover {
    text-decoration: none;
    color: #F0ECEB;
  }
  
  .footer-contact a {
    display: block; /* Makes each item (email, phone number) take a full row */
    margin: 20px 0; /* Spacing between the email and phone number */
    color: inherit; /* Ensures they use the inherited text color */
    text-decoration: none; /* Removes underline from email link */
  }
  
  /* Ensure that the hover effect doesn't add an underline */
  .footer-contact a:hover {
    text-decoration: none;
    color: #F0ECEB;
  }
  /* Additional styling for vertical lines if needed */
  
  @media (max-width: 1024px) {
    .App-footer {
      height: 400px;
      display: flex;
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center horizontally */
      justify-content: center; /* Center vertically */
      text-align: center; /* Center text for all items */
      padding: 0px; /* Add padding around the footer */
    }
  
    .footer-logo {
      width: 250px; /* Adjust logo size for mobile */
      height: auto;
      margin-bottom: 60px; /* Space below the logo */
      margin-top: -70px;
    }
  
    .footer-address,
    .footer-contact,
    .footer-privacy,
    .footer-bottom {
      width: 100%; /* Full width for alignment */
      text-align: center; /* Center text */
      margin: 0px 0; /* Add vertical spacing */
      position: static; /* Reset any relative positioning */
      font-size: 0.9em; /* Adjust font size for readability */
      margin-top: 10px;
      margin-bottom: 10px;
    }
  
    .social-icons {
      display: flex;
      justify-content: center; /* Center icons */
      gap: 20px; /* Space between icons */
      margin: 20px 0; /* Add spacing around social icons */
      top: -8px;
    }
  
    .social-icons svg {
      width: 36px; /* Adjust icon size */
      height: 36px;
      cursor: pointer;
    }
  
    .social-icons svg:hover {
      fill: #F0ECEB; /* Highlight effect on hover */
    }

    .social-icons2 {
      display: flex;
      justify-content: right;
      gap: 120px;
      margin: 0px 0; 
      /* Space between icons and contact info */
      right: 0px;
      margin-top: -240px;
      margin-bottom: -8px;
      z-index: 1;
      position: relative;
      
    }
    
    
    .social-icons2 svg {
      width: 44px;
      height: 44px;
      cursor: pointer;
    }
    
    .social-icons2 svg:hover {
      fill: #F0ECEB;
    }
  
    .footer-social-contact a,
    .footer-privacy a,
    .footer-bottom a {
      color: #F0ECEB; /* Match footer text color */
      text-decoration: none; /* Remove underline */
    }
  
    .footer-social-contact a:hover,
    .footer-privacy a:hover,
    .footer-bottom a:hover {
      color: #F0ECEB; /* Maintain hover color */
      text-decoration: none;
    }
  }
  
  @media (max-width: 768px) {
    .App-footer {
      height: 400px;
      display: flex;
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center horizontally */
      justify-content: center; /* Center vertically */
      text-align: center; /* Center text for all items */
      padding: 0px; /* Add padding around the footer */
    }
  
    .footer-logo {
      width: 250px; /* Adjust logo size for mobile */
      height: auto;
      margin-bottom: 60px; /* Space below the logo */
      margin-top: -70px;
    }
  
    .footer-address,
    .footer-contact,
    .footer-privacy,
    .footer-bottom {
      width: 100%; /* Full width for alignment */
      text-align: center; /* Center text */
      margin: 0px 0; /* Add vertical spacing */
      position: static; /* Reset any relative positioning */
      font-size: 0.9em; /* Adjust font size for readability */
      margin-top: 10px;
      margin-bottom: 20px;
    }
  
    .social-icons {
      display: flex;
      justify-content: center; /* Center icons */
      gap: 20px; /* Space between icons */
      margin: 20px 0; /* Add spacing around social icons */
      top: -8px;
    }
  
    .social-icons svg {
      width: 36px; /* Adjust icon size */
      height: 36px;
      cursor: pointer;
    }
  
    .social-icons svg:hover {
      fill: #F0ECEB; /* Highlight effect on hover */
    }

    .social-icons2 {
      display: flex;
      justify-content: right;
      gap: 120px;
      margin: 0px 0; 
      /* Space between icons and contact info */
      right: 0px;
      margin-top: -245px;
      margin-bottom: -10px;
      z-index: 1;
      position: relative;
      
    }
    
    
    .social-icons2 svg {
      width: 44px;
      height: 44px;
      cursor: pointer;
    }
    
    .social-icons2 svg:hover {
      fill: #F0ECEB;
    }
  
    .footer-social-contact a,
    .footer-privacy a,
    .footer-bottom a {
      color: #F0ECEB; /* Match footer text color */
      text-decoration: none; /* Remove underline */
    }
  
    .footer-social-contact a:hover,
    .footer-privacy a:hover,
    .footer-bottom a:hover {
      color: #F0ECEB; /* Maintain hover color */
      text-decoration: none;
    }


  }
  