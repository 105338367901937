/* Global styles and fonts */
@font-face {
  font-family: 'ALTA';
  src: url('./fonts/alta-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'ALTA', sans-serif;
  background-color: #F0ECEB;
}

.App {
  text-align: center;
  z-index: 1; 
}

.App-header {
  background-color: #243F5B;
  min-height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* This will ensure space between logo and dropdown menu */
  padding: 0 130px;
  color: #F0ECEB;
  filter: drop-shadow(0px 7px 10px #243F5B);
  z-index: 10;
}

.App-logo {
  height: 90px;
  cursor: pointer;
  /* Logo is pushed to the left with 'auto' margin on the right */
}

.DropdownMenu {
  margin-left: auto; /* This will ensure the dropdown menu is positioned correctly */
  
}


@font-face {
  font-family: 'ALTA';
  src: url('./fonts/alta-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Use the ALTA font in your body selector or wherever you wish to use it */
body {
  font-family: 'ALTA', sans-serif;
  background-color: #F0ECEB;
}

/* Slideshow Container */
.App-slideshow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Responsive height based on viewport */
  margin-top: 7vh;
  overflow: hidden;
  position: relative;
  width: 100vw;
  z-index: 2;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.fade-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 1;
}

.fade-image.visible {
  opacity: 1;
  z-index: 1;
}

/* Slideshow Text Container */
.slideshow-text {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%); /* Center the container both horizontally and vertically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Center the text inside */
  color: white;
  z-index: 3;
}

/* Font Styles for Each Text Element */
.welcome-text {
  font-family: 'Montserrat', sans-serif; /* A closer match for small header text */
  font-size: 0.7vw; /* Smaller and more proportional */
  letter-spacing: 2.9px; /* Slight spacing between letters */
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

.headline-text {
  font-family: 'ALTA', sans-serif; /* A more elegant font */
  font-size: 4.2vw; /* Larger size for main heading */
  font-weight: 500;
  margin: 30px 0;
}

.subheadline-text {
  font-family: 'Georgia', serif; /* Traditional serif font */
  font-size: 1.4vw; /* Slightly smaller for better proportion */
  margin: 5px 0 40px;
}


.book-appointment-btn {
  padding: 8px 16px; /* Adjust button padding */
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 1.2vw; /* Reduce button font size */
  font-family: 'ALTA', sans-serif;
  cursor: pointer;
  text-decoration: none; /* Removes underline */
}

.book-appointment-btn:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

/* Media Queries for Smaller Screens */
@media (max-width: 1024px) {
  .App-slideshow {
    z-index: 100;
  }

  .headline-text {
    font-size: 5vw;
  }

  .subheadline-text {
    font-size: 2.5vw;
  }

  .book-appointment-btn {
    font-size: 1.5vw;
  }

  .fade-image:nth-child(1) {
    object-position: 30% 30%;
    
  }
}

@media (max-width: 768px) {

  .App-slideshow {
    margin-top: 20px;
    z-index: 100;
  }

  .headline-text {
    font-size: 7vw;
  }

  .subheadline-text {
    font-size: 3.5vw;
  }

  .book-appointment-btn {
    font-size: 3.5vw;
  }

  /* Specific sections for each image */
  .fade-image:nth-child(1) {
    object-position: 30% 30%;
    
  }

  .fade-image:nth-child(2) {
    object-position: 40% 30%; 
    
  }

  .fade-image:nth-child(3) {
    object-position: center ; 
    
  }

  

}




/* Contact whatsapp */
.contact-image-container {
  text-align: center; /* Centers the image horizontally */
}

.contact-image {
  width: 100%;
  max-width: 1000px;
  height: auto;
  display: block;
  margin: 0px auto; 
  /* Adds spacing above and below the image and centers it */
  margin-top: 150px;
  margin-bottom: 150px;
}

.whatsapp-btn {
  position: absolute;
  top: 2720px; /* Adjust this value as needed */
  right: 630px; /* Adjust this value as needed */
  width: 170px; /* Ensures the button is square; adjust as needed */
  height: 40px; /* Matches the width to maintain square shape */
  border: 2px solid #243F5B; /* White perimeter */
  background-color: transparent; /* Transparent center */
  color: #243F5B; /* Adjust if you have a different preference */
  font-size: 16px; /* Adjust based on your design needs */
  border-radius: 0; /* Removes the rounded corners for a sharp square */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'ALTA', sans-serif;
  padding: 5px 10px; /* Add padding for visual comfort */
}

.button-image {
  width: 30px; /* Set the width of the image */
  height: 30px; /* Set the height of the image */
  margin-right: 15px; /* Space between the image and the text */
}

.whatsapp-btn:hover {
  background-color: #ffffffb5; /* Slight white tint on hover for feedback */
}


/* Footer.css */
.App-footer {
  background-color: #243F5B;
  color: #F0ECEB;
  padding: 20px 60px;
  margin-top: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-logo {
  width: 200px;
  margin-bottom: 0px; /* Space between logo and address */
}


.footer-address,
.footer-social-contact,
.footer-privacy,
.footer-bottom {
  width: 100%;
  text-align: center;
  margin: 10px 0; /* Space between rows */
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0; /* Space between icons and contact info */
}

.social-icons svg {
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.social-icons svg:hover {
  fill: #F0ECEB;
}

.footer-social-contact a,
.footer-privacy a,
.footer-bottom {
  color: #F0ECEB;
  text-decoration: none;
}

.footer-social-contact a:hover,
.footer-privacy a:hover {
  text-decoration: underline;
}


.client-login-button {
  position: absolute;
  top: 20px; /* Adjust this value as needed */
  right: 115px; /* Adjust this value as needed */
  width: 70px; /* Ensures the button is square; adjust as needed */
  height: 30px; /* Matches the width to maintain square shape */
  border: 0.5px solid white; /* White perimeter */
  background-color: transparent; /* Transparent center */
  color: white; /* Adjust if you have a different preference */
  font-size: 16px; /* Adjust based on your design needs */
  border-radius: 0; /* Removes the rounded corners for a sharp square */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'ALTA', sans-serif;
  z-index: 10;
}

.client-login-button:hover {
  background-color: rgba(255, 255, 255, 0.093);
}

/* Mobile Styles */
@media (max-width: 768px) {

  .App {
    padding: 0;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling on mobile */
  }

  .container, .main-container {
    width: 100%;
    max-width: 100vw; /* Ensure container does not exceed viewport width */
    overflow-x: hidden; /* Prevent horizontal scrolling within the container */
    padding: 0 10px; /* Add some padding to prevent content from touching the edges */
  }

  .App-header {
    background-color: #243F5B;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    color: #F0ECEB;
    filter: drop-shadow(0px 7px 10px #243F5B);
  }

  .App-logo {
    height: 90px;
    cursor: pointer;
    margin: 0 auto; /* Center the logo */
  }

  .App-slideshow {
    z-index: 1;
  }
  


}


@media (min-width: 1650px)  {
  .contact-image-container {
    text-align: center; /* Centers the image horizontally */
  }
  
  .contact-image {
    width: 1190%;
    max-width: 1300px;
    height: auto;
    display: block;
    margin: 0px auto; 
    /* Adds spacing above and below the image and centers it */
    margin-top: 200px;
    margin-bottom: 200px;
    margin-left: 220px;
  }

  .whatsapp-btn {
    position: relative;
    top: -340px; /* Adjust this value as needed */
    right: -790px; /* Adjust this value as needed */
    width: 170px; /* Ensures the button is square; adjust as needed */
    height: 45px; /* Matches the width to maintain square shape */
    border: 2px solid #243F5B; /* White perimeter */
    background-color: transparent; /* Transparent center */
    color: #243F5B; /* Adjust if you have a different preference */
    font-size: 18px; /* Adjust based on your design needs */
    border-radius: 0; /* Removes the rounded corners for a sharp square */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    padding: 5px 10px; /* Add padding for visual comfort */
  }
}


@media (min-width: 1601px) and (max-width: 1649px) {
  .contact-image-container {
    text-align: center; /* Centers the image horizontally */
  }
  
  .contact-image {
    width: 1190%;
    max-width: 1300px;
    height: auto;
    display: block;
    margin: 0px auto; 
    /* Adds spacing above and below the image and centers it */
    margin-top: 200px;
    margin-bottom: 200px;
    margin-left: 140px;
  }

  .whatsapp-btn {
    position: relative;
    top: -330px; /* Adjust this value as needed */
    right: -700px; /* Adjust this value as needed */
    width: 170px; /* Ensures the button is square; adjust as needed */
    height: 45px; /* Matches the width to maintain square shape */
    border: 2px solid #243F5B; /* White perimeter */
    background-color: transparent; /* Transparent center */
    color: #243F5B; /* Adjust if you have a different preference */
    font-size: 18px; /* Adjust based on your design needs */
    border-radius: 0; /* Removes the rounded corners for a sharp square */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    padding: 5px 10px; /* Add padding for visual comfort */
  }
}

@media (min-width: 1450px) and (max-width: 1600px) {

  .contact-image-container {
    text-align: center; /* Centers the image horizontally */
  }
  
  .contact-image {
    width: 1190%;
    max-width: 1200px;
    height: auto;
    display: block;
    margin: 0px auto; 
    /* Adds spacing above and below the image and centers it */
    margin-top: 200px;
    margin-bottom: 200px;
    margin-left: 100px;
  }

  .whatsapp-btn {
    position: relative;
    top: -320px; /* Adjust this value as needed */
    right: -620px; /* Adjust this value as needed */
    width: 160px; /* Ensures the button is square; adjust as needed */
    height: 45px; /* Matches the width to maintain square shape */
    border: 2px solid #243F5B; /* White perimeter */
    background-color: transparent; /* Transparent center */
    color: #243F5B; /* Adjust if you have a different preference */
    font-size: 16px; /* Adjust based on your design needs */
    border-radius: 0; /* Removes the rounded corners for a sharp square */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    padding: 5px 10px; /* Add padding for visual comfort */
  }
}


@media (min-width: 1300px) and (max-width: 1450px) {
  .contact-image-container {
    text-align: center; /* Centers the image horizontally */
  }
  
  .contact-image {
    width: 1100%;
    max-width: 1100px;
    height: auto;
    display: block;
    margin: 0px auto; 
    /* Adds spacing above and below the image and centers it */
    margin-top: 190px;
    margin-bottom: 190px;
    margin-left: 120px;
  }

  .whatsapp-btn {
    position: relative;
    top: -290px; /* Adjust this value as needed */
    right: -600px; /* Adjust this value as needed */
    width: 130px; /* Ensures the button is square; adjust as needed */
    height: 35px; /* Matches the width to maintain square shape */
    border: 2px solid #243F5B; /* White perimeter */
    background-color: transparent; /* Transparent center */
    color: #243F5B; /* Adjust if you have a different preference */
    font-size: 14px; /* Adjust based on your design needs */
    border-radius: 0; /* Removes the rounded corners for a sharp square */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    padding: 5px 10px; /* Add padding for visual comfort */
  }
}


@media (min-width: 1024px) and (max-width: 1299px) {
  .contact-image-container {
    text-align: center; /* Centers the image horizontally */
  }
  
  .contact-image {
    width: 1100%;
    max-width: 1100px;
    height: auto;
    display: block;
    margin: 0px auto; 
    /* Adds spacing above and below the image and centers it */
    margin-top: 190px;
    margin-bottom: 190px;
    margin-left: 60px;
  }

  .whatsapp-btn {
    position: relative;
    top: -290px; /* Adjust this value as needed */
    right: -540px; /* Adjust this value as needed */
    width: 130px; /* Ensures the button is square; adjust as needed */
    height: 35px; /* Matches the width to maintain square shape */
    border: 2px solid #243F5B; /* White perimeter */
    background-color: transparent; /* Transparent center */
    color: #243F5B; /* Adjust if you have a different preference */
    font-size: 14px; /* Adjust based on your design needs */
    border-radius: 0; /* Removes the rounded corners for a sharp square */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    padding: 5px 10px; /* Add padding for visual comfort */
  }
}

@media (min-width: 969px) and (max-width: 1023px) {
  .contact-image-container {
    text-align: center; /* Centers the image horizontally */
  }
  
  .contact-image {
    width: 1000%;
    max-width: 1000px;
    height: auto;
    display: block;
    margin: 0px auto; 
    /* Adds spacing above and below the image and centers it */
    margin-top: 250px;
    margin-bottom: 250px;
    margin-left: 40px;
  }

  .whatsapp-btn {
    position: relative;
    top: -360px; /* Adjust this value as needed */
    right: -470px; /* Adjust this value as needed */
    width: 130px; /* Ensures the button is square; adjust as needed */
    height: 35px; /* Matches the width to maintain square shape */
    border: 2px solid #243F5B; /* White perimeter */
    background-color: transparent; /* Transparent center */
    color: #243F5B; /* Adjust if you have a different preference */
    font-size: 14px; /* Adjust based on your design needs */
    border-radius: 0; /* Removes the rounded corners for a sharp square */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    padding: 5px 10px; /* Add padding for visual comfort */
  }
}

@media (min-width: 869px) and (max-width: 968px) {
  .contact-image-container {
    text-align: center; /* Centers the image horizontally */
  }
  
  .contact-image {
    width: 900%;
    max-width: 900px;
    height: auto;
    display: block;
    margin: 0px auto; 
    /* Adds spacing above and below the image and centers it */
    margin-top: 180px;
    margin-bottom: 180px;
    margin-left: 30px;
  }

  .whatsapp-btn {
    position: relative;
    top: -260px; /* Adjust this value as needed */
    right: -420px; /* Adjust this value as needed */
    width: 130px; /* Ensures the button is square; adjust as needed */
    height: 35px; /* Matches the width to maintain square shape */
    border: 2px solid #243F5B; /* White perimeter */
    background-color: transparent; /* Transparent center */
    color: #243F5B; /* Adjust if you have a different preference */
    font-size: 14px; /* Adjust based on your design needs */
    border-radius: 0; /* Removes the rounded corners for a sharp square */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    padding: 5px 10px; /* Add padding for visual comfort */
  }
}

@media (min-width: 769px) and (max-width: 868px) {
  .contact-image-container {
    text-align: center; /* Centers the image horizontally */
  }
  
  .contact-image {
    width: 900%;
    max-width: 900px;
    height: auto;
    display: block;
    margin: 0px auto; 
    /* Adds spacing above and below the image and centers it */
    margin-top: 180px;
    margin-bottom: 180px;
    margin-left: -50px;
  }

  .whatsapp-btn {
    position: relative;
    top: -260px; /* Adjust this value as needed */
    right: -330px; /* Adjust this value as needed */
    width: 130px; /* Ensures the button is square; adjust as needed */
    height: 35px; /* Matches the width to maintain square shape */
    border: 2px solid #243F5B; /* White perimeter */
    background-color: transparent; /* Transparent center */
    color: #243F5B; /* Adjust if you have a different preference */
    font-size: 14px; /* Adjust based on your design needs */
    border-radius: 0; /* Removes the rounded corners for a sharp square */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    padding: 5px 10px; /* Add padding for visual comfort */
  }
}

@media (max-width: 768px) {

  .contact-image-container {
    text-align: center; /* Centers the image horizontally */
  }
  
  .contact-image {
    width: 1000%;
    max-width: 1000px;
    height: auto;
    display: block;
    margin: 0px auto; 
    /* Adds spacing above and below the image and centers it */
    margin-top: 150px;
    margin-bottom: 150px;
    margin-left: -190px;
  }

  .whatsapp-btn {
    position: relative;
    top: -250px; /* Adjust this value as needed */
    right: -250px; /* Adjust this value as needed */
    width: 130px; /* Ensures the button is square; adjust as needed */
    height: 35px; /* Matches the width to maintain square shape */
    border: 2px solid #243F5B; /* White perimeter */
    background-color: transparent; /* Transparent center */
    color: #243F5B; /* Adjust if you have a different preference */
    font-size: 14px; /* Adjust based on your design needs */
    border-radius: 0; /* Removes the rounded corners for a sharp square */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    padding: 5px 10px; /* Add padding for visual comfort */
  }
}

@media (max-width: 568px) {

  .contact-image-container {
    text-align: center; /* Centers the image horizontally */
  }
  
  .contact-image {
    width: 1000%;
    max-width: 1000px;
    height: auto;
    display: block;
    margin: 0px auto; 
    /* Adds spacing above and below the image and centers it */
    margin-top: 180px;
    margin-bottom: 0px;
    margin-left: -270px;
  }

  .whatsapp-btn {
    position: relative;
    top: -100px; /* Adjust this value as needed */
    right: -160px; /* Adjust this value as needed */
    width: 130px; /* Ensures the button is square; adjust as needed */
    height: 35px; /* Matches the width to maintain square shape */
    border: 2px solid #243F5B; /* White perimeter */
    background-color: transparent; /* Transparent center */
    color: #243F5B; /* Adjust if you have a different preference */
    font-size: 12px; /* Adjust based on your design needs */
    border-radius: 0; /* Removes the rounded corners for a sharp square */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    padding: 5px 10px; /* Add padding for visual comfort */
  }

  .client-login-button {
    position: absolute;
    top: 20px; /* Adjust this value as needed */
    right: 25px; /* Adjust this value as needed */
    width: 70px; /* Ensures the button is square; adjust as needed */
    height: 30px; /* Matches the width to maintain square shape */
    border: 0.5px solid white; /* White perimeter */
    background-color: transparent; /* Transparent center */
    color: white; /* Adjust if you have a different preference */
    font-size: 18px; /* Adjust based on your design needs */
    border-radius: 0; /* Removes the rounded corners for a sharp square */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'ALTA', sans-serif;
    z-index: 10;
  }
  
  .client-login-button:hover {
    background-color: rgba(255, 255, 255, 0.093);
  }

}
