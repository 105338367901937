.interactive-image-container {
  position: relative;
  width: 80%; /* Full width of its container */
  height: 500px; /* Set a fixed height or make it responsive as needed */
  background-size: cover; /* Ensure the image covers the container */
  background-position: center; /* Center the background image */
  display: flex;
  justify-content: center; /* Center the child divs horizontally */
  align-items: center; /* Center the child divs vertically */
  margin-top: 300px;
  margin-left: 140px;
}

.interactive-sections {
  display: flex;
}

.interactive-sections div {
  margin: 0 0px; /* Spacing between sections */
  cursor: pointer;
  
  /* background: rgba(255, 255, 255, 0);  */
  /* Semi-transparent background */
  padding: 234px 210px;
  transition: background-color 0.3s; /* Smooth transition for background color */
  font-size: 2em; /* Increase font size here */
  color: #ffffff;
}

.interactive-sections div:hover {
  background-color: rgba(255, 255, 255, 0); /* Lighten the background on hover */
}

@media (min-width: 1650px)  {
  .interactive-image-container {
    margin-top: 400px;
    height: 600px; /* Adjust height */
    width: 1400px;
    margin-left: 170px; /* Align horizontally */
  }

  .interactive-sections {
    flex-direction: row; /* Switch to horizontal layout */
    justify-content: space-around; /* Distribute sections evenly */
    align-items: center; /* Align items vertically */
    gap: 40px; /* Space between sections */
  }

  .interactive-sections div {
    padding: 300px 270px; /* Adjust padding */
    font-size: 1.9em; /* Font size for readability */
    text-align: center; /* Center text */
  }
}


@media (min-width: 1601px) and (max-width: 1649px) {
  .interactive-image-container {
    margin-top: 400px;
    height: 600px; /* Adjust height */
    width: 1340px;
    margin-left: 140px; /* Align horizontally */
  }

  .interactive-sections {
    flex-direction: row; /* Switch to horizontal layout */
    justify-content: space-around; /* Distribute sections evenly */
    align-items: center; /* Align items vertically */
    gap: 40px; /* Space between sections */
  }

  .interactive-sections div {
    padding: 300px 280px; /* Adjust padding */
    font-size: 1.2em; /* Font size for readability */
    text-align: center; /* Center text */
  }
}


@media (min-width: 1450px) and (max-width: 1600px) {
  .interactive-image-container {
    margin-top: 400px;
    height: 490px; /* Adjust height */
    width: 1140px;
    margin-left: 150px; /* Align horizontally */
  }

  .interactive-sections {
    flex-direction: row; /* Switch to horizontal layout */
    justify-content: space-around; /* Distribute sections evenly */
    align-items: center; /* Align items vertically */
    gap: 40px; /* Space between sections */
  }

  .interactive-sections div {
    padding: 250px 230px; /* Adjust padding */
    font-size: 1.5em; /* Font size for readability */
    text-align: center; /* Center text */
  }
}

@media (min-width: 1300px) and (max-width: 1450px) {
  .interactive-image-container {
    margin-top: 400px;
    height: 490px; /* Adjust height */
    width: 1140px;
    margin-left: 110px; /* Align horizontally */
  }

  .interactive-sections {
    flex-direction: row; /* Switch to horizontal layout */
    justify-content: space-around; /* Distribute sections evenly */
    align-items: center; /* Align items vertically */
    gap: 40px; /* Space between sections */
  }

  .interactive-sections div {
    padding: 250px 220px; /* Adjust padding */
    font-size: 1.5em; /* Font size for readability */
    text-align: center; /* Center text */
  }
}
/* For medium-sized images (1024px to 1599px) */
@media (min-width: 1024px) and (max-width: 1299px) {
  .interactive-image-container {
    margin-top: 300px;
    height: 430px; /* Adjust height */
    width: 980px;
    margin-left: 100px; /* Align horizontally */
  }

  .interactive-sections {
    flex-direction: row; /* Switch to horizontal layout */
    justify-content: space-around; /* Distribute sections evenly */
    align-items: center; /* Align items vertically */
    gap: 40px; /* Space between sections */
  }

  .interactive-sections div {
    padding: 200px 190px; /* Adjust padding */
    font-size: 1.4em; /* Font size for readability */
    text-align: center; /* Center text */
  }
}



@media (min-width: 969px) and (max-width: 1023px) {
  .interactive-image-container {
    margin-top: 300px;
    height: 400px; /* Adjust height */
    width: 900px;
    margin-left: 70px; /* Align horizontally */
  }

  .interactive-sections {
    flex-direction: row; /* Switch to horizontal layout */
    justify-content: space-around; /* Distribute sections evenly */
    align-items: center; /* Align items vertically */
    gap: 40px; /* Space between sections */
  }

  .interactive-sections div {
    padding: 250px 150px; /* Adjust padding */
    font-size: 1.2em; /* Font size for readability */
    text-align: center; /* Center text */
  }
}

@media (min-width: 869px) and (max-width: 968px) {
  .interactive-image-container {
    margin-top: 300px;
    height: 370px; /* Adjust height */
    width: 820px;
    margin-left: 70px; /* Align horizontally */
  }

  .interactive-sections {
    flex-direction: row; /* Switch to horizontal layout */
    justify-content: space-around; /* Distribute sections evenly */
    align-items: center; /* Align items vertically */
    gap: 40px; /* Space between sections */
  }

  .interactive-sections div {
    padding: 150px 120px; /* Adjust padding */
    font-size: 1.4em; /* Font size for readability */
    text-align: center; /* Center text */
  }
}

@media (min-width: 769px) and (max-width: 868px) {
  .interactive-image-container {
    margin-top: 300px;
    height: 290px; /* Adjust height */
    width: 650px;
    margin-left: 70px; /* Align horizontally */
  }

  .interactive-sections {
    flex-direction: row; /* Switch to horizontal layout */
    justify-content: space-around; /* Distribute sections evenly */
    align-items: center; /* Align items vertically */
    gap: 40px; /* Space between sections */
  }

  .interactive-sections div {
    padding: 150px 120px; /* Adjust padding */
    font-size: 1.4em; /* Font size for readability */
    text-align: center; /* Center text */
  }
}


/* For extra-small images (below 768px) */
@media (max-width: 768px) {
  .interactive-image-container {
    height: 700px; /* Further reduce height for extra-small screens */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    margin-left: 80px;
  }

  .interactive-sections {
    flex-direction: column; /* Stack sections vertically */
    justify-content: center; /* Ensure items are centered */
    align-items: center; /* Align items to the center */
    gap: 20px; /* Add spacing between sections */
  }

  .interactive-sections div {
    padding: 150px 30px; /* Compact padding for mobile devices */
    font-size: 1.4em; /* Adjust font size for readability */
    text-align: center; /* Center text within each section */
    
  }
}

@media (max-width: 568px) {
  .interactive-image-container {
    height: 500px; /* Further reduce height for extra-small screens */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    margin-left: 45px;
  }

  .interactive-sections {
    flex-direction: column; /* Stack sections vertically */
    justify-content: center; /* Ensure items are centered */
    align-items: center; /* Align items to the center */
    gap: 20px; /* Add spacing between sections */
  }

  .interactive-sections div {
    padding: 110px 170px; /* Compact padding for mobile devices */
    font-size: 1.3em; /* Adjust font size for readability */
    text-align: center; /* Center text within each section */
    
  }
}