/* Ensure the dropdown container is positioned relative to handle z-index correctly */
.dropdown-container {
  position: relative;
  display: inline-block;
  float: right;
  z-index: 99999; /* Increase this further */
}

.dropdown-logo {
  height: 55px;
  cursor: pointer;
  float: right;
  margin-right: 19px;
}

/* Dropdown content styling */
.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #f0eceb00;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0);
  z-index: 99999; /* Increase this to be higher than other elements */
}

.dropdown-content a {
  color: #243F5B;
  text-decoration: none;
  padding: 35px 30px;
  display: inline-block;
  white-space: nowrap;
  font-size: 1.15em;
  transition: transform 0.3s ease;
  z-index: 99999; 
}

.dropdown-content a:hover {
  color: #A3897B;
  background-color: #1e345000;
  transform: scale(1.1);
  z-index: 99999; 
}

/* Display the dropdown content when it's open */
.dropdown-container .dropdown-content {
  display: flex;
  z-index: 99999; /* Ensure it stays above everything */
}

/* Mobile Styles */
/* Mobile Styles */
/* Mobile Styles */
@media (max-width: 768px) {
  /* Dropdown content styling */
  .dropdown-content {
    display: none;
    position: fixed; /* Fixed to prevent scrolling */
    right: -80px;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--dropdown-bg, #f0eceb); /* Background customizable */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 99999;
    flex-direction: column;
    align-items: flex-start; /* Align menu items to the left */
    padding: 20px;
    overflow: hidden; /* Prevent scrolling within the dropdown */
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: var(--dropdown-text-color, #243F5B);
    text-decoration: none;
    padding: 15px 10px;
    display: block;
    white-space: nowrap;
    font-size: 1.5em;
    transition: color 0.3s ease, transform 0.3s ease;
  }

  /* Hover effect for links */
  .dropdown-content a:hover {
    color: var(--dropdown-hover-color, #A3897B);
    transform: scale(1.1);
  }

  /* Make dropdown visible */
  .dropdown-container .dropdown-content {
    display: flex;
  }

  /* Close button (only for mobile) */
  .close-btn {
    position: absolute;
    top: 20px;
    right: 60px;
    font-size: 40px;
    cursor: pointer;
    color: var(--close-btn-color, #243F5B);
    background: none;
    border: none;
    z-index: 10000;
    display: block; /* Show only on mobile */
  }

  /* Header adjustments */
  .App-header {
    position: relative;
    z-index: 10000;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: var(--header-bg, #243F5B);
    align-items: center;
  }

  /* Ensure slideshow remains below dropdown */
  .App-slideshow {
    z-index: 1;
  }

  /* Image below menu options */
  .menu-image {
    margin-top: 0px;
    width: 100px;
    height: 100px;
    border-radius: 8px; /* Optional: Adds rounded corners */
    
  }
}

/* Desktop Styles (Hide Close Button) */
@media (min-width: 769px) {
  .close-btn {
    display: none; /* Hide close button on desktop */
  }
  .menu-image {
    display: none; /* Hide the image on desktop */
  }
}
